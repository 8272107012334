import React, { useState } from "react";
import "../styles/Form.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function Form() {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [email, setEmail] = useState("");
  const [selectedNbUser, setSelectedNbUser] = useState(null);
  const [selectedEcheance, setSelectedEcheance] = useState(null);
  const inputFilter = /^[a-zA-Z0-9\s]*$/;

  const handleCaptchaChange = (value) => {
    // Mettre à jour la valeur du CAPTCHA lorsqu'elle change
    setCaptchaValue(value);
  };

  const sendForm = async (event) => {
    event.preventDefault();

    if (!captchaValue) {
      // Ne pas envoyer le formulaire si le CAPTCHA n'est pas résolu
      alert("Veuillez résoudre le CAPTCHA avant d'envoyer le formulaire.");
      return;
    }

    setIsSending(true);

    const firstname = event.target.firstname.value;
    const lastname = event.target.lastname.value;
    const email = event.target.email.value;
    const phone = event.target.phone.value;
    const company = event.target.company.value;

    const combinedValue = company && selectedEcheance
      ? `${company} | (échéance: ${selectedEcheance})`
      : company || '';

    const requestBody = {
      firstname,
      lastname,
      email,
      phone,
      company: combinedValue,
      nbUser: selectedNbUser,
    };

    const request = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const response = await request.json();

    if (response?.success) {
      setIsSent(true);
    } else {
      setIsSending(false);
      alert("Une erreur s'est produite, veuillez réessayer.");
    }
  };

  const nbUserOptions = [
    { name: '1 à 3 salariés', code: '1-3' },
    { name: '4 à 10 salariés', code: '4-10' },
    { name: '11 à 20 salariés', code: '11-20' },
    { name: '21 à 50 salariés', code: '21-50' },
    { name: '51 à 100 salariés', code: '51-100' },
    { name: '100 salariés et plus', code: '100+' }
  ];

  const echeanceProjet = [
    { name: "Moins d'un mois", code: '1' },
    { name: 'De 1 à 3 mois', code: '1-3' },
    { name: 'De 3 à 6 mois', code: '3-6' },
    { name: 'De 6 à 12 mois', code: '6-12' },
    { name: 'Plus de 12 mois', code: '12+' }
  ];

  const handleDropdownChange = (e) => {
    setSelectedNbUser(e.value.name);
  };

  const handleDropdownEcheance = (e) => {
    setSelectedEcheance(e.value.name);
  };



  return (
    <>
      <form onSubmit={sendForm}>
        <div className="form">
          <div className="flex flex-column gap-1">
            <h4 className="form-title text-center">Vous souhaitez souscrire au<br /> Pack Business Pro ?</h4>
            <p className="c-black text-center mb-3"><b>Demandez un devis gratuit sous 24h</b></p>
            <div className="flex flex-column">
              <label htmlFor="firstname" className="c-black">Prénom</label>
              <InputText
                className="input p-inputtext-sm"
                id="firstname"
                keyfilter={inputFilter}
                aria-describedby="username-help"
                required
              />
            </div>
            <div className="flex flex-column">
              <label htmlFor="lastname" className="c-black">Nom</label>
              <InputText
                className="input p-inputtext-sm"
                id="lastname"
                keyfilter={inputFilter}
                aria-describedby="username-help"
                required
              />
            </div>

            <div className="flex flex-column">
              <label htmlFor="email" className="c-black">Email</label>
              <InputText
                className="input p-inputtext-sm"
                id="email"
                keyfilter="email"
                aria-describedby="username-help"
                required
                value={email} // Définir la valeur de l'email
                onChange={(e) => setEmail(e.target.value)} // Mettre à jour l'état de l'email
              />
            </div>

            <div className="flex flex-column">
              <label htmlFor="phone" className="c-black">Téléphone</label>
              <InputText
                className="input p-inputtext-sm"
                keyfilter={/^[0-9]+$/}
                id="phone"
                aria-describedby="username-help"
                placeholder="06 01 02 03 04"
                minLength="10"
                maxLength="10"
                pattern="[0][0-9]{9}"
                required
              />
            </div>

            <div className="flex flex-column">
              <label htmlFor="company" className="c-black">Société</label>
              <InputText
                className="input p-inputtext-sm"
                keyfilter={inputFilter}
                id="company"
                aria-describedby="username-help"
                required
              />
            </div>

            <div className="flex flex-column">
              <label htmlFor="echeance" className="c-black">Echéance</label>
              <Dropdown
                required
                value={echeanceProjet.find(option => option.name === selectedEcheance) || null}
                onChange={handleDropdownEcheance}
                options={echeanceProjet}
                optionLabel="name"
                placeholder="Sélectionner"
                className="w-full"
              />
            </div>

            <div className="flex flex-column">
              <label htmlFor="nbUser" className="c-black">Nb. de salariés</label>
              <Dropdown
                required
                value={nbUserOptions.find(option => option.name === selectedNbUser) || null}
                onChange={handleDropdownChange}
                options={nbUserOptions}
                optionLabel="name"
                placeholder="Sélectionner"
                className="w-full"
              />
            </div>

            <div className="col-12">
              <HCaptcha
                sitekey="c763dcc3-11af-4d19-a403-64c5587118b3"
                onVerify={(token) => handleCaptchaChange(token)}
              />
            </div>

            <div className="button-form">
              {isSending || isSent ? (
                <Button
                  type="submit"
                  className="submit"
                  severity="danger"
                  raised
                  disabled={isSending || isSent}
                  label={
                    isSent
                      ? "ENVOYÉ"
                      : (
                        <span>
                          ENVOI EN COURS
                          {isSending && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '0.5rem' }}></i>}
                        </span>
                      )
                  }
                  style={{ fontWeight: "0" }}
                />
              ) : (
                <Button
                  type="submit"
                  className="submit"
                  severity="danger"
                  label="Confirmer"
                  value="Confirmer"
                  raised
                  style={{ fontWeight: "0" }}
                />
              )}
            </div>
          </div>
          <p style={{ fontSize: '11px' }} className="text-center c-black mt-4">COLOR TELECOM recueille ces informations pour gérer votre demande de devis, vous adresser ses offres commerciales et marketing, et suivre les performances de ses campagnes publicitaires.</p>
        </div>
      </form>
    </>
  );
}

export default Form;
