import React from 'react';
import '../styles/Options.css';
import man_pic from '../assets/Options/man.png';
import img_banner from "../assets/img_banner.png";
import bo from '../assets/Options/bo.png';
import woman_pic from '../assets/Options/woman.png';
import DevisButton from './DevisButton';

export default function Options() {
    return (
        <>
            <div className='flex flex-column justify-content-center align-items-center gap-8'>
                <div className="flex flex-row flex-wrap justify-content-center gap-8">
                    <img src={img_banner} alt='' className='pic-size-banner' />
                    <div className="flex flex-column justify-content-center text-justify">
                        <div className="flex flex-column gap-3 w-600">
                            <h2>Fibre Pro Sécurisée</h2>
                            <p className='maxW-100'>Profitez du Très Haut Débit pour votre entreprise : Jusqu’à 1 Gb/s avec un raccordement FTTH. Basculez automatiquement sur un routeur 4G en cas de coupure de votre connexion internet (Option).</p>
                            <div className="w-200">
                                <DevisButton backgroundColor="black" color="white" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap justify-content-center gap-8">
                    <div className="flex flex-column justify-content-center text-justify">
                        <div className="flex flex-column gap-3 w-600">
                            <h2>Standard téléphonique</h2>
                            <p>Profitez d’un Standard Téléphonique Professionnel avec appels sortants inclus vers Fixes et Mobiles. Créez votre annonce d’accueil, choisissez votre musique d’attente et indiquez les collaborateurs qui doivent répondent aux appels, le tout sur une interface intuitive 100% en ligne.</p>
                            <div className="w-200">
                                <DevisButton backgroundColor="black" color="white" />
                            </div>
                        </div>
                    </div>
                    <img src={woman_pic} alt='' className='pic-size' />
                </div>
                <div className="flex flex-row flex-wrap justify-content-center gap-8">
                    <img src={man_pic} alt='' className='pic-size' />
                    <div className="flex flex-column justify-content-center text-justify">
                        <div className="flex flex-column gap-3 w-600">
                            <h2>Convergence Fixe-Mobile</h2>
                            <p>Recevez et passez des appels depuis le bureau, mais aussi en mobilité et télétravail grâce à l’application sur mobile et ordinateur.</p>
                            <div className="w-200">
                                <DevisButton backgroundColor="black" color="white" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-column align-items-center text-center gap-6 mt-8">
                <h1>Profitez d’une interface intuitive avec des statistiques complètes<br /> pour un suivi précis de chaque appel</h1>
                <img src={bo} alt='' className='bo-pic' />
                <div className="glimmer-orange"></div>
                <div className="glimmer-blue"></div>
            </div>
        </>
    );
}
