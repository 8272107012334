import React from 'react';
import '../styles/DevisButton.css';

export default function DevisButton({ backgroundColor, color }) {
    const style = {
        padding: '1rem',
        borderRadius: '39px',
        fontWeight: '500',
        color: color,
        cursor: "pointer",
        backgroundColor: backgroundColor,
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div style={style} onClick={scrollToTop}>
            <p className='w-auto'>Demander un devis</p>
        </div>
    );
}
