import React, { useRef } from "react";
import "../styles/BlockBottom.css";
import img_pack from "../assets/logo_pack_pro.png"
import man from "../assets/bottom-logo/man_bottom.png";
import check from "../assets/check.svg";

export default function BlockBottom() {
  const emailInputRef = useRef(null);

  // Fonction pour rediriger l'utilisateur vers l'étape 1 après avoir saisi son e-mail
  const redirect = () => {
    const email = emailInputRef.current.value;
    // Vérification de l'email pour s'assurer qu'il ne contient que des caractères alphanumériques et le caractère '@'
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      alert("Veuillez entrer une adresse e-mail valide.");
      return;
    }
    const url = `https://commande.standardenligne.fr/inscription/choix-numero?email=${email}`;
    window.location.href = url;
  };


  return (
    <>
      <div className="flex">
        <div className="grid grid-nogutter">
          <div className="col-5 flex justify-content-end w-auto-responive">
            <img
              className="pic-customer border-left"
              src={man}
              alt="Customer"
            ></img>
          </div>
          <div
            className="col-7 blackBlock flex justify-content-center bg-black border-right"
            style={{
              color: "white",
              padding: "2rem",
            }}
          >
            <div className="flex flex-column gap-5 justify-content-center w-full content-block">
              <div className="flex flex-row align-items-center gap-3">
                <img src={img_pack} alt="" className='w-auto' />
                <p style={{ fontSize: '25px' }}>Pack Business Pro</p>
              </div>
              <div
                className="flex w-full"
                style={{ fontSize: "36px", fontWeight: "600" }}
              >
                Une Box tout inclus qui sécurise l’activité de votre entreprise
              </div>
              <div className="flex">
                <div className="flex justify-content-start">
                  <div className="bottom-checks flex flex-wrap flex-row gap-3">
                    <div className="flex">
                      <img src={check} alt="Check"></img> Fibre dédiée aux Pros
                    </div>
                    <div className="flex">
                      <img src={check} alt="Check"></img> Standard Téléphonique
                    </div>
                    <div className="flex">
                      <img src={check} alt="Check"></img> Appels inclus vers fixes et mobiles
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
