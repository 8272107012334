import React from "react";
import Header from "./Header";
import PackBusinessPro from "./PackBusinessPro";
import Options from "./Options";
import GroupBlocks from "./GroupBlocks";
import Customers from "./Customers";
import Faq from "./Faq";
import BlockBottom from "./BlockBottom";
import NewFooter from "./NewFooter";
import { useMediaQuery } from 'react-responsive';

export function App() {
  const isScreenWidthBelow1050 = useMediaQuery({ query: '(max-width: 1050px)' });

  return (
    <>
      <div>
        <Header />
      </div>
      <PackBusinessPro />
      <div className="mt-5">
        <Customers />
      </div>
      <div className="mt-8">
        <Options />
      </div>
      <div className="mt-8">
        <GroupBlocks />
      </div>
      <div className="mt-8">
        {!isScreenWidthBelow1050 && <Faq />}
      </div>
      <div className="mt-8">
        <BlockBottom />
      </div>
      <div className="mt-8">
        <NewFooter />
      </div>
    </>
  );
}

export default App;
