import React from 'react';
import '../styles/Customers.css';
import allianz from '../assets/customers/allianz.png';
import croix_rouge from '../assets/customers/croix_rouge.png';
import engie from '../assets/customers/engie.png';
import procter from '../assets/customers/procter.png';
import suez from '../assets/customers/suez.png';
import lynx from '../assets/customers/lynx.png';
import aquilarh from '../assets/customer-webp/aquilarh.webp';
import attila from '../assets/customer-webp/attila.webp';
import anc from '../assets/customer-webp/anc.webp';
import clinique from '../assets/customer-webp/clinique.webp';
import mobilite from '../assets/customers/mobilite.svg';
import ratp from '../assets/customers/ratp.jpg';
import xlink from '../assets/customers/xlink.svg';
import blachere from '../assets/customer-webp/blachere.webp';
import soleil from '../assets/customers/soleil.png';
import keylodge from '../assets/customers/keylodge.png';
import evreux from '../assets/customers/evreux.png';
import cdos from '../assets/customer-webp/cdos.webp';
import lpo from '../assets/customers/lpo.jpeg';
import ugecam from '../assets/customers/ugecam.svg';
import am from '../assets/customers/am.svg';


const logos = [
    { src: allianz, alt: 'allianz', className:'logo-allianz' },
    { src: croix_rouge, alt: 'croix_rouge' },
    { src: engie, alt: 'engie' },
    { src: procter, alt: 'procter' },
    { src: suez, alt: 'suez', className:'logo-suez' },
    { src: lynx, alt: 'lynx', className: 'logo-lynx' },
    { src: aquilarh, alt: 'aquilarh', className: 'logo-aquilarh' },
    { src: attila, alt: 'attila' },
    { src: anc, alt: 'anc' },
    { src: clinique, alt: 'clinique' },
    { src: mobilite, alt: 'mobilite' },
    { src: ratp, alt: 'ratp' },
    { src: xlink, alt: 'xlink' },
    { src: blachere, alt: 'blachere' },
    { src: soleil, alt: 'soleil' },
    { src: keylodge, alt: 'keylodge' },
    { src: evreux, alt: 'evreux' },
    { src: lpo, alt: 'lpo' },
    { src: ugecam, alt: 'ugecam' },
    { src: am, alt: 'am' },
    { src: cdos, alt: 'cdos', className:'logo-cdos' },

];

export default function Customers() {
    return (
        <div className="flex flex-column justify-content-center align-items-center customers-container gap-5">
            <h1>Ils nous font confiance</h1>
            <div className="logos">
                {logos.concat(logos).map((logo, index) => (
                    <img 
                        key={index} 
                        src={logo.src} 
                        alt={logo.alt} 
                        className={`logo ${logo.className || ''}`} 
                    />
                ))}
            </div>
        </div>
    );
}
