import React from "react";
import "../styles/GroupBlocks.css";
import appels from "../assets/GroupBlocks/appels.png";
import ad from "../assets/GroupBlocks/ad.png";
import music from "../assets/GroupBlocks/music.png";
import renvoi from "../assets/GroupBlocks/renvoi_appel.png";
import home from "../assets/GroupBlocks/home.png";
import customer from "../assets/GroupBlocks/customer.png";
import DevisButton from "./DevisButton";

export default function GroupBlocks() {
    const scrollToBottom = () => {
        const windowHeight = window.innerHeight;
        const fullHeight = document.documentElement.scrollHeight;
        const scrollTo = fullHeight - windowHeight;

        window.scrollTo({
            top: scrollTo,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="bg-black pt-5">
                <div className="flex justify-content-center">
                    <p
                        className="tel-cloud"
                        style={{
                            fontSize: "2vw",
                            fontWeight: "600",
                            color: "white",
                            textAlign: "center",
                        }}
                    >
                        Toutes les fonctionnalités d’un<br /> véritable Standard Téléphonique<br /> Professionnel
                    </p>
                </div>
                <div>
                    <div className="flex flex-column flex-wrap align-items-center">
                        <div className="flex">
                            <div className="flex flex-row justify-content-center flex-wrap gap-4">
                                <div className="flex responsive-layout">
                                    <div className="accueil flex flex-column grey-bg justify-content-between">
                                        <div className="flex">
                                            <img alt="Téléphonie professionnel" src={home}></img>
                                        </div>
                                        <div className="flex">
                                            <p className="font-25 mb-0" style={{ color: "white" }}>
                                                Accueil téléphonique
                                                <br /> professionnel
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="flex flex-column align-items-start justify-content-center responsive-layout gap-5 ">
                                        <div className="flex grey-bg w-full width-auto">
                                            <div className="flex flex-column align-items-start blocks">
                                                <div className="flex">
                                                    <img className="w-auto" alt="Studio en ligne" src={ad}></img>
                                                </div>
                                                <div className="flex">
                                                    <img className="w-auto" alt="suivi appel" src={music}></img>
                                                </div>
                                                <div className="flex">
                                                    <p className="font-25 mb-0">Studio en ligne</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex grey-bg w-full width-auto blocks">
                                            <div className="flex justify-content-center flex-column">
                                                <div className="flex">
                                                    <img className="w-auto" alt="renvoi appel" src={appels}></img>
                                                </div>
                                                <div className="flex">
                                                    <p className="font-25 mb-0"> Suivez vos appels</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex" style={{ marginTop: "2rem" }}>
                            <div className="flex flex-wrap justify-content-center flex-row flex-wrap gap-5">
                                <div className="flex">
                                    <div className="flex flex-wrap flex-column grey-bg">
                                        <div className="flex">
                                            <img className="w-full" alt="renvoi appel" src={renvoi}></img>
                                        </div>
                                        <div className="flex">
                                            <p className="font-25 mb-0">Et bien + encore</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex responsive-layout">
                                    <div className="customer-responsive">
                                        <img
                                            className="picture-customer w-full h-full"
                                            src={customer}
                                            alt="client"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-content-center p-5"
                    >
                        <DevisButton backgroundColor="white" />

                    </div>
                </div>
            </div>
        </>
    );
}
