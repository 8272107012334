import { Panel } from 'primereact/panel';
import { useRef } from 'react';
import '../styles/faq.css';


export default function Faq() {
    const ref = useRef(null);

    return (
        <>
            <h1 className='text-center mb-6'>Tout savoir sur le Pack Business PRO</h1>
            <div className="faq-div">
                <div className='flex flex-column align-items-center justify-content-center'>
                    <div className="flex flex-column gap-3 space">
                        <div>
                            <Panel className="panel faq-div" collapsed ref={ref} header="Comment la Fibre Professionnelle sécurise mon activité au quotidien ?" toggleable>
                                <p className="m-0 mb-5 text-justify">
                                    La Fibre Pro de Standard en ligne vous permet d’obtenir une connexion internet sécurisée et en très haut début :Déploiement rapide : Votre entreprise est connectée à la fibre en quelques jours seulement.Vous recevez le matériel sous 48h après la souscription
                                    <ul className='mt-3 mb-3'>
                                        <li>Un technicien se déplace pour effectuer le raccordement
                                        </li>
                                        <li>Vous n’avez pas besoin d’attente la mise en service de la fibre optique pour utiliser les services de téléphonie inclus dans votre abonnement.
                                        </li>
                                    </ul>
                                    Très Haut Débit : La Fibre Pro offre un débit descendant jusqu’à 1 Gb/s avec une Wifi ultra rapideBackup 4G de secours : Profitez d’une connexion sécurisée grâce au routeur 4G de secours. Votre connexion internet bascule automatiquement en 4G lorsque le routeur fibre détecte un incident ou une anomalie.
                                </p>
                            </Panel>
                        </div>
                        <div>
                            <Panel className="panel" collapsed ref={ref} header="Quelles sont les fonctionnalités professionnelles disponibles avec le Standard Téléphonique de Standard en ligne ?" toggleable>
                                <p className="m-0 mb-5 text-justify">
                                    Obtenez un accueil téléphonique personnalisé : Avec le standard téléphonique virtuel hébergé en mode Centrex par Standard en ligne, vous bénéficiez d’un accueil téléphonique professionnel grâce aux fonctionnalités complètes de Standard téléphonique sur votre ligne fixe. Vous choisissez un numéro facile à retenir (ou conservez celui que vous avez déjà), configurez en quelques clics le message d’accueil, la musique d’attente, la gestion des horaires et la distribution des appels vers vos collaborateurs.
                                    <p className='mt-2'>Gérez en quelques clics votre standard téléphonique sans aucune connaissance technique grâce à une interface intuitive.</p>
                                    <p className='mt-2 mb-2'>Accédez à des fonctionnalités personnalisées.</p>
                                    Message d’accueil : Personnalisez l’accueil de votre standard téléphonique au nom de votre entreprise
                                    <ul>
                                        <li><b>Musique d’attente :</b> Faites patienter vos appelants en musique
                                        </li>
                                        <li><b>Gestion des horaires :</b> Paramétrez les horaires d’ouvertures de votre standard téléphonique
                                        </li>
                                        <li><b>Redirections d’appels :</b> Redirigez les appels entrants vers le bon service ou les bons interlocuteurs
                                        </li>
                                    </ul>
                                </p>
                            </Panel>
                        </div>
                        <div>
                            <Panel className="panel" collapsed ref={ref} header="Quelle téléphonie est incluse dans le Pack Business Pro ?" toggleable>
                                <p className="m-0 mb-5 text-justify">
                                    Vous bénéficiez d’une ligne fixe rattachée au Standard Téléphonique avec appels entrants et sortants inclus vers les Fixes et Mobiles en France Métropolitaine. En supplément, vous pouvez appeler les Fixes et les Mobiles vers plusieurs destinations internationales. Chaque utilisateur dispose de sa propre ligne directe en plus du numéro d’accueil du standard téléphonique. Vous pouvez ajouter un poste fixe sur votre bureau et profiter des applications pour téléphoner facilement au bureau et en mobilité.                            </p>
                            </Panel>
                        </div>
                        <div>
                            <Panel className="panel" collapsed ref={ref} header="Quelles sont les applications fournies avec le Pack Business Pro ?" toggleable>
                                <p className="m-0 mb-5 text-justify">
                                    Profitez des applications softphones disponibles sur mobile et ordinateur : Votre bureau devient mobile. Gérez facilement vos appels en mobilité et en télétravail grâce à la suite applicative de Standard en ligne. Compatibles Carplay et Android Auto, recevez et passez vos appels lors de vos trajets professionnels directement depuis l’application.                            </p>
                            </Panel>
                        </div>
                        <div>
                            <Panel className="panel" collapsed ref={ref} header="Qu’est-il possible de faire sur l’espace client Standard en ligne ?" toggleable>
                                <p className="m-0 mb-5 text-justify">
                                    Standard en ligne a développé un espace client 100% en ligne, intuitif qui vous permet de gérer l’intégralité de votre téléphonie d’entreprise :
                                    <ul>
                                        <li>Configuration: Grâce à une interface fluide sur ordinateur et mobile, paramétrez en toute autonomie votre standard téléphonique Message d’accueil, message d’attente, musique d’attente, gestion des horaires, distribution des appels.
                                        </li>
                                        <li>Supervision Statistique : Grâce au journal d’appel CRM, contrôlez les appels clients reçus sur le standard téléphonique et annotez les appels importants.
                                        </li>
                                    </ul><br />
                                    Gestion: Gérez votre contrat en toute simplicité : Consultez vos factures, ajoutez du crédit, ajoutez des options, modifiez votre offre
                                </p>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
