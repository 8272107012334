import React, { useState } from "react";
import logo from "../assets/logo.png";
import "../styles/Header.css";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputMask } from "primereact/inputmask";
import { useMediaQuery } from 'react-responsive';


function Header() {
  const isScreenWidthBelow1050 = useMediaQuery({ query: '(max-width: 650px)' });
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(01|02|03|04|05|06|07|09)\s\d{2}\s\d{2}\s\d{2}\s\d{2}$/;
    return phoneRegex.test(phone);
  };

  const sendForm = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const phone = formData.get('phone');

    if (!phone || !validatePhoneNumber(phone)) {
      alert("Le numéro doit correspondre au format français: 01, 02, 03, 04, 05, 06, 07 ou 09.");
      return;
    }

    setIsSending(true);

    const requestBody = {
      firstname: "//",
      lastname: "//",
      email: "//",
      phone,
      company: "//",
      nbUser: "//",
    };

    try {
      const response = await fetch("https://env-3387615.jcloud-ver-jpe.ik-server.com/api/v1/email/mail-business-pro", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (result?.success) {
        setIsSent(true);
      } else {
        alert("Erreur lors de l'envoi de la requête. Veuillez réessayer.");
      }
    } catch (error) {
      alert("Erreur lors de l'envoi de la requête. Veuillez réessayer.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="fixed-header">
      <div style={{ backgroundColor: 'white' }} className="flex flex-wrap flex-row justify-content-around align-items-center p-3 gap-5 header-content">
        <div className="flex">
          <a href="https://www.standardenligne.fr/"><img src={logo} className="" alt="Logo" /></a>
        </div>
        <div className="flex flex-row flex-wrap align-items-center gap-6">
          <div>
            <button className="button-edit" onClick={() => setVisible(true)}><i style={{ fontSize: '17px' }} className="pi pi-phone"></i> <b>Être rappelé(e)</b></button>
            <Dialog
              visible={visible}
              onHide={() => setVisible(false)}
              draggable={false}
            >
              <form onSubmit={sendForm} className="flex flex-column align-items-center gap-4">
                <h3>Vous souhaitez être rappelé(e) ?</h3>
                <p>Saisissez votre numéro</p>
                <InputMask
                  name="phone"
                  className="inputmask-number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  mask="99 99 99 99 99"
                  placeholder="XX XX XX XX XX"
                  required
                />
                <small className="text-center">Le numéro doit correspondre au format français: 01, 02, 03, 04,<br /> 05, 06, 07 ou 09.</small>
                <Button type="submit" severity="danger" label={isSending ? "Envoi en cours..." : "Être rappelé(e)"} disabled={isSending} />
                {isSent && <p>Votre numéro a bien été envoyé</p>}
              </form>
            </Dialog>
          </div>
          <p className="button-edit-vert c-white"><i style={{ fontSize: '20px' }} className="pi pi-phone"></i><b> 01 87 200 200</b></p>
        </div>
      </div>
      {!isScreenWidthBelow1050 &&
        <div className="flex justify-content-center align-items-center p-3 band">
          <p className="flex justify-content-center align-items-center"><b>Besoin d'aide pour choisir une box ? Nous vous <span className="m-0" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setVisible(true)}>rappelons immédiatement.</span></b><i style={{ fontSize: '18px' }} className="pi pi-phone ml-3"></i></p>
        </div>
      }

    </div>
  );
}

export default Header;
