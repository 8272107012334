import React from 'react';
import '../styles/PackBusinessPro.css';
import img_pack from "../assets/logo_pack_pro.png";
import img_banner from "../assets/img_banner.png";
import Form from '../components/Form';

export default function PackBusinessPro() {
    return (
        <div className='flex flex-column justify-content-center align-items-center black-bg f-white p-4 gap-8'>
            <div className='pack-content'>
                <div className="flex flex-column f-white gap-4 text-center align-items-center">
                    <div className="flex flex-row align-items-center gap-3">
                        <img src={img_pack} alt='' className='w-auto'/>
                        <p style={{ fontSize: '25px' }}>Pack Business Pro</p>
                    </div>
                    <h1>Une BOX Pro tout inclus<br /> pour les TPE & PME</h1>
                    <p style={{maxWidth:'800px'}}>Découvrez le Pack Business Pro, solution complète de téléphonie et de connectivité qui s’adapte<br /> à vos besoins</p>
                    <img src={img_banner} alt="" className='img-banner' />
                </div>
                <div className="flex justify-content-center form-container">
                    <Form />
                </div>
            </div>
        </div>
    );
}
