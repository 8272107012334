import React from 'react'
import '../styles/Footer.css'
import logo from '../assets/logo.png'


export default function NewFooter() {
    return (
        <div className='flex flex-row flex-wrap justify-content-center gap-8 footer'>
            <img className='h-full' src={logo} alt="" />
            <div style={{width:'60%'}}>
                <p className='text-center'>© 2023 SAS COLOR TELECOM – 525 053 617 R.C.S. Nanterre – SIRET : 52505361700045 – NAF : 7112B – TVA :
                    FR76525053617 | siège social : COLOR TELECOM, 7 Rue du 4 Septembre 92170 Vanves, France</p>
            </div>
        </div>
    )
}
